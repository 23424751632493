import { TFunction } from 'i18next';
import sConfigs from '../_configs/_configs';
import { CarDataFields, ConfigObject } from '../_configs/types';
import { FluxStoreModuleContent } from '../_flux/types';
import mapFacets from '../_json/mapFacets';
import { Modules } from '../_json/modules';
import sStrDatafields from '../_json/sStrDatafields';
import sAttr from '../_json/s_attr';
import { Car, Facets, FacetsService, Filter, ViewOptions } from '../_types/Car';
import { Translations } from '../locales/translations';
import { arrAus } from '../modules/fahrzeugsuche/filter/filterList';
import { LOCALSERVER, MetaData } from '../vars';
import { generateLink } from './generateLink';
import { stringToSlug } from './string-to-slug';
import { checkUndefinedNullOrEmpty, checkUndefinedOrNull, uniqueArray } from './tools';

export type CommonMapProps = {
    i: string | number,
    t: string
};

/** @deprecated Use direct access to object instead. */
export function getFieldFromObject(object: any, field: string, fallback?: any): any | null | undefined {
    if (checkUndefinedOrNull(object)) {
        if (LOCALSERVER) console.error('Object ist leer!');
        return fallback;
    }
    if (typeof object !== 'object') {
        if (LOCALSERVER) console.error('Object ist kein Objekt!');
        return fallback;
    }
    if (checkUndefinedOrNull(field)) {
        if (LOCALSERVER) console.error('Field ist leer!');
        return fallback;
    }

    const fieldArray = field && field.split('.');
    let workObj = Object.assign({}, object);
    for (let i = 0; i < fieldArray.length; i++) {
        if (!checkUndefinedOrNull(workObj) && !checkUndefinedOrNull(workObj[fieldArray[i]])) {
            workObj = workObj[fieldArray[i]];
        } else {
            workObj = fallback;
            break;
        }
    }
    return workObj;
}

export function formatText<T>(string: string, ...args): T {
    if (checkUndefinedOrNull(string)) {
        return null;
    }

    const returnArray = [];
    const match = string.match(/{([0-9]+)}/g);

    if (checkUndefinedOrNull(match)) {
        // @ts-ignore
        return string;
    }

    let stringOnly = true;
    for (let i = 0; i < match.length; i++) {
        const splitArray = string.split(match[i]);
        returnArray.push(splitArray[0]);
        returnArray.push(args[i]);
        string = string?.substring(string.indexOf(match[i]) + match[i].length);
        if (stringOnly && typeof args[i] !== 'string') {
            stringOnly = false;
        }
    }
    returnArray.push(string);
    // @ts-ignore
    return stringOnly ? returnArray.join('') : returnArray;
}

export function stringReplaceAt(content: string, index: number, contentToReplaceLength: number, replacement: string) {
    return `${content?.substring(0, index)}${replacement}${content?.substring(index + contentToReplaceLength)}`;
}

export function getSearchString(filter: Filter, facets: Facets, viewOptions: FluxStoreModuleContent['viewOptions'], fiz: boolean, questionMark: boolean, translate: TFunction, settings: ConfigObject, octo: true): { keyword: string, category: string }[];
export function getSearchString(filter: Filter, facets: Facets, viewOptions: FluxStoreModuleContent['viewOptions'], fiz: boolean, questionMark: boolean, translate: TFunction, settings: ConfigObject, octo?: false | undefined): string;
export function getSearchString(filter: Filter, facets: Facets, viewOptions: FluxStoreModuleContent['viewOptions'], fiz: boolean, questionMark: boolean, translate: TFunction, settings: ConfigObject, octo?: boolean): string | { keyword: string, category: string }[] {
    const searchArray = [];
    const searchOcto: { keyword: string, category: string }[] = [];
    const mappedFacets = mapFacets(translate);
    const sAttrTranslate = sAttr(translate);
    if (!octo && !checkUndefinedNullOrEmpty(viewOptions) && !checkUndefinedNullOrEmpty(viewOptions?.car)) {
        searchArray.push(viewOptions?.car?.join('_carId'));
    } else if (!checkUndefinedNullOrEmpty(filter)) {
        if (fiz) searchArray.push(['fiz', 1].join('='));
        if (filter.page && filter.page > 1) {
            searchArray.push(['page', filter.page].join('='));
            searchOcto.push({ category: 'page', keyword: filter?.page?.toString() });
        }
        for (let i = 0; i < Object.keys(filter).length; i++) {
            const key = Object.keys(filter)[i];
            const value = filter?.[key];
            if (key === 'ia') {
                const searchStringAus = [];
                Object.keys(sAttrTranslate).map(function (catAus) {
                    sAttrTranslate[catAus].map(function (aus) {
                        if (value.includes(aus.i)) {
                            searchStringAus.push(aus.t);
                            searchOcto.push({ category: key, keyword: aus?.t });
                        }
                    });
                });
                if (searchStringAus.length > 0) {
                    searchArray.push([key, stringToSlug(searchStringAus.join(','))].join('='));
                }
            } else if (key === 'motoren' || key === 'akttypList' || key === 'Farben' || key === 'fd') {
                const facet = mappedFacets?.[key];
                const array = facets[facet?.facetName as keyof FacetsService];
                const title = facet?.filterName;

                if (!checkUndefinedNullOrEmpty(title) && !checkUndefinedNullOrEmpty(value)) {
                    const valueArr = [];
                    value?.map(function (val) {
                        const text = array?.find((a) => a?.i === val)?.t;
                        valueArr.push(text);
                        searchOcto.push({ category: key, keyword: text });
                    });
                    searchArray.push([key, stringToSlug(valueArr.join(','))].join('='));
                }
            } else {
                const facet = mappedFacets?.[key];
                const array = facets[facet?.facetName as keyof FacetsService];
                const title = facet?.filterName;

                if (!checkUndefinedNullOrEmpty(title) && !checkUndefinedNullOrEmpty(value) && value !== -100 && value !== '-100' && value !== false) {
                    if (Array.isArray(value)) {
                        if (!checkUndefinedNullOrEmpty(value[0]) && value[0] !== -100 && value[0] !== '-100' && value[0] !== false) {
                            searchArray.push([key + 'Von', value[0]].join('='));
                            searchOcto.push({ category: key + 'Von', keyword: value[0] });
                        }
                        if (!checkUndefinedNullOrEmpty(value[1]) && value[1] !== -100 && value[1] !== '-100' && value[1] !== false) {
                            searchArray.push([key + 'Bis', value[1]].join('='));
                            searchOcto.push({ category: key + 'Bis', keyword: value[1] });
                        }
                    } else {
                        const text = key === 'freier_text' ? value : ['sofort', 'aktion'].includes(key) ? 'Ja' : array?.find((a) => a?.i === value)?.t;
                        searchArray.push([key, stringToSlug(text)].join('='));
                        searchOcto.push({ category: key, keyword: stringToSlug(text) });
                    }
                }
            }
        }
    }
    if (!checkUndefinedNullOrEmpty(settings?.general?.searchPathSearch)) {
        searchArray.unshift(settings?.general?.searchPathSearch.replace('?', ''));
    }

    if (octo) {
        return searchOcto;
    }

    const searchString = searchArray.join('&');

    return (questionMark ? '?' : '') + searchString?.substring(0, searchString.length - (searchString.endsWith('&') ? 1 : 0));
}

export function getSearchFromString(searchString: string, facets: Facets, translate: TFunction): {
    filter: Filter,
    viewOptions: ViewOptions,
} {
    const carListFilter: Filter = {};
    const carListViewOptions: ViewOptions = {};
    const workSearchString: string = decodeURIComponent(searchString).replace(/^\?/g, '');
    const mappedFacets = mapFacets(translate);
    const sAttrTranslate = arrAus(translate);

    if (!checkUndefinedOrNull(workSearchString)) {
        const checkEinzelansicht = workSearchString.match(/.*_carId[0-9]+/g);
        if (!checkUndefinedNullOrEmpty(checkEinzelansicht)) {
            const searchSplit = checkEinzelansicht[0].split('_carId');
            carListViewOptions.car = [searchSplit[0], parseFloat(searchSplit[1])];
        } else {
            carListViewOptions.car = undefined;
            const arrSplit = workSearchString?.split('&');
            for (let i = 0; i < arrSplit.length; i++) {
                const splitter = arrSplit[i];
                if (!checkUndefinedNullOrEmpty(splitter)) {
                    const split = splitter?.split('=');
                    const filterKey = split?.[0] as ((keyof Filter) | 'fiz');
                    const searchedFacet = mappedFacets?.[filterKey];
                    const searchedValue = !checkUndefinedNullOrEmpty(split?.[1]) ? split?.[1] : undefined;

                    if (!checkUndefinedNullOrEmpty(searchedValue)) {
                        if (filterKey === 'ia') {
                            const arr = [];
                            searchedValue.split(',').map(function (item) {
                                const value = sAttrTranslate?.find((a) => a?.tSlug === stringToSlug(item))?.i;
                                if (!checkUndefinedNullOrEmpty(value)) {
                                    arr.push(value);
                                }
                            });
                            carListFilter[filterKey] = arr;
                        } else if (
                            filterKey === 'motoren'
                            || filterKey === 'akttypList'
                            || filterKey === 'aufbauten'
                            || filterKey === 'Farben'
                            || filterKey === 'Standort'
                            || filterKey === 'idFirma'
                            || filterKey === 'fd'
                        ) {
                            const arr = [];
                            searchedValue.split(',').map(function (item) {
                                const value = !checkUndefinedNullOrEmpty(searchedFacet?.facetName) ? facets?.[searchedFacet?.facetName]?.find((f) => f?.tSlug === stringToSlug(item))?.i : undefined;
                                if (!checkUndefinedNullOrEmpty(value)) {
                                    arr.push(value);
                                }
                            });
                            carListFilter[filterKey] = arr;
                        } else if (
                            filterKey === 'RateVon'
                            || filterKey === 'RateBis'
                            || filterKey === 'EZVon'
                            || filterKey === 'EZBis'
                            || filterKey === 'KMVon'
                            || filterKey === 'KMBis'
                            || filterKey === 'LeistungVon'
                            || filterKey === 'LeistungBis'
                            || filterKey === 'PreisVon'
                            || filterKey === 'PreisBis'
                        ) {
                            if (checkUndefinedNullOrEmpty(carListFilter[filterKey?.replace('Von', '')?.replace('Bis', '')])) {
                                const data = [...(facets?.[searchedFacet?.facetName]?.map((e) => e?.i) || []).map(function (e) { return ({ i: parseInt(e.toString()) }); })];
                                const von = Math.min(...(data?.map((e) => e?.i) || []));
                                const bis = Math.max(...(data?.map((e) => e?.i) || []));
                                carListFilter[filterKey?.replace('Von', '')?.replace('Bis', '')] = [von, bis];
                            }
                            carListFilter[filterKey?.replace('Von', '')?.replace('Bis', '')][filterKey?.endsWith('Bis') ? 1 : 0] = parseFloat(searchedValue);
                        } else if (
                            filterKey === 'sofort'
                            || filterKey === 'aktion'
                        ) {
                            carListFilter[filterKey] = searchedValue === 'Ja';
                        } else if (filterKey === 'freier_text') {
                            carListFilter[filterKey] = searchedValue;
                        } else {
                            const value = !checkUndefinedNullOrEmpty(searchedFacet?.facetName) ? facets?.[searchedFacet?.facetName]?.find((f) => f?.tSlug === stringToSlug(searchedValue))?.i : undefined;
                            const valueIsNumber = new RegExp(/^\d+$/).test(value);
                            carListFilter[filterKey] = valueIsNumber ? parseInt(value) : value;
                        }
                    }
                }
            }
        }
    }

    return {
        filter: carListFilter,
        viewOptions: carListViewOptions,
    };
}

export function getSearchHeading(translate: TFunction, allFacets: Facets, filter: Filter, settings: ConfigObject): { withPage: string, withoutPage: string } {
    const returnArrayWithoutPage = [];
    const locationFacetKey = settings?.general?.isGruppe ? 'firmenAll' : 'standortAll';
    const locationFilterKey = settings?.general?.isGruppe ? 'idFirma' : 'Standort';
    // only add hersteller to heading if no model filter; model already includes hersteller name, otherwise "Audi Audi A6"
    if (!checkUndefinedNullOrEmpty(filter?.hersteller0) && filter?.hersteller0 > -1 && filter?.modell0 <= -1) {
        const hs = allFacets?.herstellerAll?.find((f) => f?.i === filter?.hersteller0)?.t;
        returnArrayWithoutPage.push(hs);

        // if no type filter like "used cars", add "cars" for better SEO
        if (checkUndefinedNullOrEmpty(filter?.akttypList)) {
            returnArrayWithoutPage.push(translate('common:buttons.vehicles'));
        }
    }
    if (!checkUndefinedNullOrEmpty(filter?.modell0) && filter?.modell0 > -1) {
        const md = allFacets?.modellAll?.find((f) => f?.i === filter?.modell0)?.t;
        returnArrayWithoutPage.push(md);
    }
    if (!checkUndefinedNullOrEmpty(filter?.tags) && filter?.tags !== '-100') {
        const tag = allFacets?.tagsAll?.find((f) => f?.i === filter?.tags)?.t;
        returnArrayWithoutPage.push(tag);
    } else if (!checkUndefinedNullOrEmpty(filter?.akttypList)) {
        const typArr = [];
        let typLast = '';
        const newFilterArray = uniqueArray(filter?.akttypList?.map((val) => (val > 0 && val !== 1 ? 2 : val)))?.sort();
        newFilterArray?.map(function (t, i) {
            const typ: string = translate(`common:car.types.plural.${t?.toString() as keyof Translations['car']['types']['plural']}`);
            if (i !== (newFilterArray?.length - 1)) {
                typArr.push(typ.replace('wagen', '-'));
            } else {
                typLast = typ;
            }
        });
        if (typArr.length > 0) {
            returnArrayWithoutPage.push([typArr.join(', '), typLast].join(` ${translate('common:common.and')} `));
        } else {
            returnArrayWithoutPage.push(typLast);
        }
    }

    if (returnArrayWithoutPage.length === 0) {
        returnArrayWithoutPage.push(translate('common:carSearch.heading'));
    }

    // end with customer name or locations for SEO
    if (!checkUndefinedNullOrEmpty(filter?.[locationFilterKey])) {
        const loc = [];
        filter?.[locationFilterKey]?.map((val) => {
            const location = allFacets?.[locationFacetKey]?.find((f) => f?.i === val)?.sd;
            loc.push(location?.ort);
        });
        returnArrayWithoutPage.push(`${translate('common:common.in')} ${uniqueArray(loc)?.join(', ')}`);
    } else {
        returnArrayWithoutPage.push(`${translate('common:common.at')} ${settings?.general?.projectName}`);
    }

    return {
        withoutPage: returnArrayWithoutPage?.join(' '),
        withPage: (
            [
                ...returnArrayWithoutPage,
                !checkUndefinedNullOrEmpty(filter?.page) && filter?.page > 1 ? ` - ${translate('common:carSearch.page')} ${filter?.page}` : undefined,
            ]?.filter((e) => !checkUndefinedNullOrEmpty(e))?.join(' ')
        ),
    };
}

export function getSearchDesc(translate: TFunction, facets?: Facets, filter?: Filter) {
    const ret = [];

    if (!checkUndefinedOrNull(filter?.hersteller0) && filter?.hersteller0 >= 0) {
        const hs = facets?.herstellerAll?.find((f) => f?.i === filter?.hersteller0)?.t;
        ret.push(`${translate('common:filter.hersteller')}: ${hs}`);
    }

    if (!checkUndefinedOrNull(filter?.modell0) && filter?.modell0 >= 0) {
        const md = facets?.modellAll?.find((f) => f?.i === filter?.modell0)?.t;
        ret.push(`${translate('common:filter.modell')}: ${md}`);
    }

    if (!checkUndefinedOrNull(filter?.MotorG) && filter?.MotorG >= 0) {
        const motor = facets?.MotorGAll?.find((f) => f?.i === filter?.MotorG)?.t;
        ret.push(`${translate('common:filter.motor')}: ${motor}`);
    } else if (!checkUndefinedNullOrEmpty(filter?.motoren)) {
        const motorArr = [];
        filter?.motoren.map(function (t) {
            const motorRaw = facets?.MotorGAll?.find((f) => f?.i === t)?.t;
            motorArr.push(motorRaw);
        });
        ret.push(`${translate('common:filter.motor')}: ${motorArr.join(', ')}`);
    }

    if (!checkUndefinedOrNull(filter?.Getriebe) && filter?.Getriebe >= 0) {
        const getr = facets?.getriebeAll?.find((f) => f?.i === filter?.Getriebe)?.t;
        ret.push(`${translate('common:filter.getriebe')}: ${getr}`);
    }

    if (!checkUndefinedNullOrEmpty(filter?.akttypList)) {
        const typArr = [];
        let typLast = '';
        const newFilterArray = uniqueArray(filter?.akttypList?.map((val) => (val > 0 && val !== 1 ? 2 : val)))?.sort();
        newFilterArray?.map(function (t, i) {
            const typ: string = translate(`common:car.types.plural.${t?.toString() as keyof Translations['car']['types']['plural']}`);
            if (i !== (newFilterArray?.length - 1)) {
                typArr.push(typ.replace('wagen', '-'));
            } else {
                typLast = typ;
            }
        });
        if (typArr.length > 0) {
            ret.push([typArr.join(', '), typLast].join(` ${translate('common:common.and')} `));
        } else {
            ret.push(typLast);
        }
    } else {
        ret.push(`${translate('common:filter.typ')}: ${translate(facets?.firmen?.[0]?.i !== 54630 ? 'common:carSearch.heading' : 'common:carSearch.heading2')}`);
    }

    if (!checkUndefinedOrNull(filter?.tags)) {
        const tags = facets?.tagsAll?.find((f) => f?.i === filter?.tags)?.t;
        ret.push(`${translate('common:filter.tags')}: ${tags}`);
    }

    if (!checkUndefinedNullOrEmpty(filter?.Rate)) {
        const pr = filter?.Rate[0] + '€ - ' + filter?.Rate[1] + '€';
        ret.push(`${translate('common:filter.rate')}: ${pr}`);
    } else if (!checkUndefinedNullOrEmpty(filter?.Preis)) {
        const pr = filter?.Preis[0] + '€ - ' + filter?.Preis[1] + '€';
        ret.push(`${translate('common:filter.preis')}: ${pr}`);
    }

    if (!checkUndefinedNullOrEmpty(filter?.EZ)) {
        const ez = filter?.EZ[0] + ' - ' + filter?.EZ[1];
        ret.push(`${translate('common:filter.ez')}: ${ez}`);
    }

    if (!checkUndefinedNullOrEmpty(filter?.ia)) {
        const aus = [];
        const sAttrTranslate = sAttr(translate);
        const sAttrAreas: Record<'innen' | 'aussen' | 'assistenz' | 'sonstiges', string[]> = Object.keys(sAttrTranslate)?.reduce((p, c) => ({
            ...p,
            [c]: sAttrTranslate?.[c]?.map((a) => a?.i),
        }), { innen: [], aussen: [], assistenz: [], sonstiges: [] });
        filter?.ia.map(function (a) {
            let map = [];
            if (sAttrAreas?.innen?.includes(a)) {
                map = sAttrTranslate.innen;
            } else if (sAttrAreas?.aussen?.includes(a)) {
                map = sAttrTranslate.aussen;
            } else if (sAttrAreas?.assistenz?.includes(a)) {
                map = sAttrTranslate.assistenz;
            } else if (sAttrAreas?.sonstiges?.includes(a)) {
                map = sAttrTranslate.sonstiges;
            }
            aus.push(map?.find((m) => m?.i === a)?.t);
        });
        ret.push(`${translate('common:filter.aus')}: ${aus.join(', ')}`);
    }

    if (!checkUndefinedOrNull(filter?.page) && filter?.page > 1) {
        ret.push(`- ${translate('common:carSearch.page')} ${filter?.page}`);
    }
    return ret.join(' | ');
}

export function generateTitle(titleStr: string, noLimit: boolean, settings: ConfigObject) {
    const title = [];
    const lengthStaticTitle = noLimit ? 0 : (!checkUndefinedNullOrEmpty(settings?.general?.metaTitlePrefix) ? (settings?.general?.metaTitlePrefix.length + (!checkUndefinedNullOrEmpty(settings?.general?.metaTitleSeperator) ? settings?.general?.metaTitleSeperator.length : 3) + 2) : 0) + (!checkUndefinedNullOrEmpty(settings?.general?.metaTitleSuffix) ? (settings?.general?.metaTitleSuffix.length + (!checkUndefinedNullOrEmpty(settings?.general?.metaTitleSeperator) ? settings?.general?.metaTitleSeperator.length : 3) + 2) : 0);
    if (!checkUndefinedNullOrEmpty(settings?.general?.metaTitlePrefix)) title.push(settings?.general?.metaTitlePrefix);
    if (!checkUndefinedNullOrEmpty(titleStr)) title.push(titleStr?.substring(0, (60 - lengthStaticTitle)));
    if (!checkUndefinedNullOrEmpty(settings?.general?.metaTitleSuffix)) title.push(settings?.general?.metaTitleSuffix);

    return title.join(settings?.general?.metaTitleSeperator);
}

export function getSearchShare(translate: TFunction, facets: Facets, filter: Filter, settings: ConfigObject) {
    const searchHeading = getSearchHeading(translate, facets, filter, settings);
    return generateTitle(`Fahrzeugsuche: ${searchHeading}`, true, settings);
}

export function getCarMeta(translate: TFunction, car: Car<false>, settings: ConfigObject, module: Modules): MetaData {
    const meta: MetaData = { desc: undefined, image: undefined, imageAlt: undefined, tags: undefined, title: undefined, titleRaw: undefined, url: undefined };
    meta.image = car?.bilder?.url?.[0];
    meta.imageAlt = `Fahrzeugbild ${car?.name}`;
    meta.url = generateLink({ car, module, pathOnly: false, settings, type: 'car' });
    meta.title = car?.name;
    meta.titleRaw = car?.name;

    const dataFields = sStrDatafields(translate, settings);
    const infoGridArray: CarDataFields[] = settings?.fahrzeugdetail?.infoGrid?.[car?.kategorie] || settings?.fahrzeugdetail?.infoGrid?.default || [];
    const properties: [string, string][] = [
        [undefined, [car?.hersteller, car?.modell, car?.typ]?.filter((val) => !checkUndefinedNullOrEmpty(val))?.join(' ')],
        ...(infoGridArray.reduce((prev, curr) => {
            const clearKey = curr?.replace('[G]', '')?.replace('[L]', '') as CarDataFields;
            const keyToShow = clearKey === 'siegel_garantie' ? (!checkUndefinedNullOrEmpty(car?.siegel) && car?.siegel !== 0 ? 'siegel' : 'garantie') : clearKey;
            const itemToShow = dataFields?.find((f) => f?.i === keyToShow);
            const rawResult = !checkUndefinedNullOrEmpty(itemToShow?.renderTextValue) ? itemToShow?.renderTextValue(car) : !checkUndefinedNullOrEmpty(itemToShow?.renderValue) ? itemToShow?.renderValue(car) : getFieldFromObject(car, itemToShow?.i, undefined);
            const result = !checkUndefinedNullOrEmpty(rawResult) && !['(unbekannt)'].includes(rawResult.toString()) ? rawResult : '-';
            if (!checkUndefinedNullOrEmpty(result) && result !== '-') {
                return [...prev, [itemToShow?.t, result]];
            } else {
                return prev;
            }
        }, [])),
    ];

    meta.desc = properties?.reduce((prev, curr) => [...prev, !checkUndefinedNullOrEmpty(curr[0]) ? `${curr[0]}: ${curr[1]}` : curr[1]], [])?.join(', ');
    meta.tags = properties?.reduce((prev, curr) => [...prev, curr[1]], [])?.join(', ');

    return meta;
}

const configFields = {
    'data-general-debug': 'general.debug',
    'data-general-islandingpage': 'general.isLandingpage',
    'data-general-mockup': 'general.mockup',
    'data-general-initialsearchpath': 'general.initialSearchPath',
    'data-general-searchpath': 'general.searchPath',
    'data-general-usecanonicallinks': 'general.useCanonicalLinks',
    'data-contact-linkdisclaimer': 'contact.linkDisclaimer',
    'data-modulesettings-defaultfilter': '[module].defaultFilter',
    'data-modulesettings-onefilterperrow': '[module].oneFilterPerRow',
    'data-style-background': 'style.background',
};

function handleDeprecatedFields(s: ConfigObject) {
    const deprecatedFields = [];
    const newS: ConfigObject = Object.assign({}, s);
    deprecatedFields.map(function () {
        //
    });
    return newS;
}

export function createConfigObject(module: Modules, domContainerId: string, debug: boolean): ConfigObject {
    const additionalConfig: Partial<ConfigObject> = {};

    if (debug) {
        console.log(`${module || 'entry'} - createConfigObject - module`, module);
        console.log(`${module || 'entry'} - domContainerId`, domContainerId);
    }
    if (!checkUndefinedNullOrEmpty(domContainerId)) {
        const container = document.getElementById(domContainerId);
        if (debug) {
            console.log(`${module || 'entry'} - container`, container);
        }
        if (!checkUndefinedOrNull(container)) {
            Object.keys(configFields).map(function (field) {
                const configFieldOfContainer = container.getAttribute(field);
                const valueToUse = configFieldOfContainer === 'true' ? true : configFieldOfContainer === 'false' ? false : parseFloat(configFieldOfContainer) ? parseFloat(configFieldOfContainer) : configFieldOfContainer;
                if (debug) {
                    console.log(`${module || 'entry'} - field`, field);
                    console.log(`${module || 'entry'} - configFieldOfContainer`, configFieldOfContainer);
                    console.log(`${module || 'entry'} - valueToUse`, valueToUse);
                }
                if (!checkUndefinedNullOrEmpty(valueToUse)) {
                    const fieldTree = configFields[field].replace('[module]', module).split('.');
                    if (fieldTree.length === 3) {
                        if (checkUndefinedOrNull(additionalConfig[fieldTree[0]])) {
                            additionalConfig[fieldTree[0]] = {};
                        }
                        if (checkUndefinedOrNull(additionalConfig[fieldTree[0][fieldTree[1]]])) {
                            additionalConfig[fieldTree[0][fieldTree[1]]] = {};
                        }
                        additionalConfig[fieldTree[0]][fieldTree[1]][fieldTree[2]] = valueToUse;
                    } else if (fieldTree.length === 2) {
                        if (checkUndefinedOrNull(additionalConfig[fieldTree[0]])) {
                            additionalConfig[fieldTree[0]] = {};
                        }
                        additionalConfig[fieldTree[0]][fieldTree[1]] = valueToUse;
                    } else if (fieldTree.length === 1) {
                        additionalConfig[fieldTree[0]] = valueToUse;
                    } else {
                        console.error('You cannot edit the whole config object!');
                    }
                }
            });
        }
    }

    const newSettings = sConfigs(!checkUndefinedNullOrEmpty(module) ? { ...additionalConfig, domContainerId } : {});
    if (debug) {
        console.log(`${module || 'entry'} - additionalConfig`, additionalConfig);
        console.log(`${module || 'entry'} - newSettings`, newSettings);
    }
    newSettings.general.debug = debug;
    return (handleDeprecatedFields(newSettings));
}
