import { createSlice, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { Modules } from '../../_json/modules';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { standardViewOptions } from '../defaults';
import { FluxStoreModuleContent } from '../types';

const fahrzeugsucheSlice = createSlice<FluxStoreModuleContent, SliceCaseReducers<FluxStoreModuleContent>, Modules.Fahrzeugsuche, SliceSelectors<FluxStoreModuleContent>>({
    name: Modules.Fahrzeugsuche,
    initialState: { rootContainer: undefined, viewOptions: standardViewOptions },
    reducers: {
        setRootContainer(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.rootContainer = action.payload;
            }
        },
        setViewOptions(state, action) {
            if (!checkUndefinedNullOrEmpty(action.payload)) {
                state.viewOptions = {
                    ...state.viewOptions,
                    ...action.payload,
                };
            }
        },
    },
});

export const actions = fahrzeugsucheSlice.actions;
export const reducer = fahrzeugsucheSlice.reducer;
